// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import {parse_qs} from './path.js';

export const stringify_state = (extra={})=>{
    const params = {...parse_qs(), ...extra};
    if (!Object.keys(params).length)
        return null;
    return `state=${encodeURIComponent(JSON.stringify(params))}`;
};

export const parse_state = ()=>{
    const {state} = parse_qs();
    if (!state)
        return {};
    try {
        const parsed = JSON.parse(state);
        return parsed || {};
    } catch(e){
        return {};
    }
};
