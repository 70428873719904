// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import {useState, useEffect, useCallback} from 'react';
import etask from '/util/etask.js';
import {ms} from '/util/date.js';
import {rand_range} from '/util/rand.js';
import * as api from './api.js';
import {OFFER_STATUS, OFFERS_SORTING} from './utils/constants.js';

const E = {};
const DEBUG = false;
const LABELS = {
    DEVICES: 'devices',
    MONEY: 'money',
    DOWNLOADS: 'downloads',
    METHODS: 'methods',
    TRANSACTIONS: 'transactions',
    REFEREES: 'referees',
    REFEREES_BVPN: 'referees_bvpn',
    COUNTERS: 'counters',
    NOTIFS: 'notifs',
    BONUSES: 'bonuses',
    USAGE: 'usage',
    OFFERS: 'offers',
    OFFERS_PAGE: 'offers_page',
    STARTED_OFFERS: 'started_offers',
    COMPLETED_OFFERS: 'completed_offers',
};

function create_fetch_hook({
    fetch_data,
    default_val={},
    label='',
    refresh=0,
    cache=true,
    paginated=false,
})
{
    const listeners = {};
    const resets = {};
    let data_cache = null;
    let error_cache = false;
    let page_cache = 0;
    let fetching = false;
    let serial = 0;
    const hook = function use_fetch(no_fetch, fetch_params){
        const [id] = useState(()=>serial++);
        const [page, set_page] = useState(page_cache);
        const [should_reset, set_reset] = useState(false);
        const [data, set_data] = useState(data_cache);
        const [error, set_error] = useState(null);
        const [loading, set_loading] = useState(fetching);
        if (!cache&&data_cache)
        {
            setTimeout(()=>{
                data_cache = null;
                error_cache = false;
            }, ms.SEC);
        }
        const change_page = useCallback(num=>{
            if (num==page)
                return;
            set_page(num);
            set_reset(true);
        }, [page]);
        const inc_page = useCallback(()=>change_page(page+1), [page]);
        const dec_page = useCallback(()=>{
            change_page(Math.max(0, page-1));
        }, [page]);
        const reset = useCallback(()=>{
            if (DEBUG)
                console.log('reset triggered', id);
            set_reset(true);
            set_page(page_cache);
        }, []);
        if (DEBUG)
        {
            console.log(`${label}_${id} triggered`);
            console.log('listeners', listeners);
            console.log('resets', resets);
            console.log('----');
        }
        const sync_data = useCallback(skip=>{
            if (skip)
                return;
            set_data(data_cache);
            set_error(error_cache);
            set_loading(fetching);
            if (paginated)
                set_page(page_cache);
        }, []);
        useEffect(()=>{
            resets[id] = reset;
            return ()=>{
                delete listeners[id];
                delete resets[id];
            };
        }, []);
        useEffect(()=>{
            if (no_fetch)
                return;
            listeners[id] = sync_data;
            etask(function*(){
                if (fetching)
                    return;
                if (data_cache&&!should_reset)
                    return void set_data(data_cache);
                this.finally(()=>{
                    set_loading(false);
                    set_reset(false);
                    fetching = false;
                    for (const k in listeners)
                        listeners[k](k==id);
                });
                set_loading(true);
                fetching = true;
                try {
                    const params={...fetch_params};
                    if (paginated)
                        params.page = page;
                    const res = yield fetch_data(params);
                    if (res.error)
                        throw res.error;
                    data_cache = res;
                    page_cache = page;
                    set_data(data_cache);
                    error_cache = null;
                } catch(e){
                    set_error(e);
                    error_cache = e;
                }
            });
            return ()=>{
                delete listeners[id];
            };
        }, [no_fetch, should_reset, page]);
        const res = [data||default_val, error, loading];
        if (paginated)
        {
            res.push({
                page,
                max: data?.pagination.max||0,
                set: change_page,
                inc: inc_page,
                dec: dec_page,
            });
        }
        return res;
    };
    hook.label = label;
    hook.invalidate = ()=>{
        if (DEBUG)
            console.log('invalidate', resets);
        data_cache = null;
        error_cache = false;
        page_cache = 0;
        fetching = false;
        for (const id in resets)
            resets[id]?.();
    };
    if (refresh)
        setInterval(hook.invalidate, refresh);
    return hook;
}

const get_key = key=>`use_${key}`;

const add_hook = hook=>{
    E[get_key(hook.label)] = hook;
};

add_hook(create_fetch_hook({
    fetch_data: ()=>etask(function*(){
        const res = yield api.fetch_devices();
        if (res.error)
            return res;
        return {list: res};
    }),
    default_val: {mock: 1, list: []},
    label: LABELS.DEVICES,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_money(),
    default_val: {mock: 1},
    label: LABELS.MONEY,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_downloads(),
    default_val: {mock: 1},
    label: LABELS.DOWNLOADS,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_payment_methods(),
    default_val: {paypal: false, amazon: [], wise: false},
    label: LABELS.METHODS,
}));

add_hook(create_fetch_hook({
    fetch_data: opts=>api.get_usage(opts?.step),
    default_val: {mock: 1, list: []},
    label: LABELS.USAGE,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>etask(function*(){
        const res = yield api.fetch_transactions();
        if (res.error)
            return res;
        return {list: res};
    }),
    default_val: {mock: 1, list: []},
    label: LABELS.TRANSACTIONS,
}));

add_hook(create_fetch_hook({
    fetch_data: params=>api.fetch_referees(params.page),
    paginated: true,
    default_val: {mock: 1, list: [], total: 0},
    label: LABELS.REFEREES,
}));

add_hook(create_fetch_hook({
    fetch_data: params=>api.fetch_referees_bvpn(params.page),
    paginated: true,
    default_val: {mock: 1, list: [], total: 0},
    label: LABELS.REFEREES_BVPN,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_counters(),
    default_val: {mock: 1},
    label: LABELS.COUNTERS,
    cache: false,
    refresh: ms.MIN*rand_range(3, 6),
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_notifs(),
    default_val: {mock: 1, list: []},
    label: LABELS.NOTIFS,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_bonuses(),
    default_val: {mock: 1, list: []},
    label: LABELS.BONUSES,
}));

add_hook(create_fetch_hook({
    fetch_data: ()=>api.fetch_offers(),
    default_val: {type: '', data: []},
    label: LABELS.OFFERS,
}));

add_hook(create_fetch_hook({
    fetch_data: params=>api.fetch_offers_page(params),
    paginated: true,
    default_val: {type: '', data: []},
    label: LABELS.OFFERS_PAGE,
}));

add_hook(create_fetch_hook({
    fetch_data: params=>api.fetch_offers_status({
        ...params,
        status: OFFER_STATUS.IN_PROGRESS,
        order_by: OFFERS_SORTING.START_TS_91,
    }),
    paginated: true,
    default_val: {type: '', data: []},
    label: LABELS.STARTED_OFFERS,
}));

add_hook(create_fetch_hook({
    fetch_data: params=>api.fetch_offers_status({
        ...params,
        status: OFFER_STATUS.COMPLETED,
        order_by: OFFERS_SORTING.START_TS_91,
    }),
    paginated: true,
    default_val: {type: '', data: []},
    label: LABELS.COMPLETED_OFFERS,
}));

E.invalidate = ()=>{
    for (const l of Object.keys(LABELS))
        E[get_key(LABELS[l])].invalidate();
};

export default E;
