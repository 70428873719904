// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Button} from '../../comp/button';
import './social.less';

export function Social({className, items}){
    return <div className={cn('ea_social', className)}>
      {items.map(({url, name, ico})=><Button href={url} key={url}
        className="eass_link" title={name} ico={ico} hide_new_tab_ico
        color_scheme="tertiary" filled>
      </Button>)}
    </div>;
}
