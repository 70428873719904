// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import url from '/util/url.js';
import {PREFIX, PLATFORMS, ROUTES, FAQ_LINK_INSTALL} from './constants.js';
import {supports} from './features.js';

export const get_path = path=>path+window.location.search;

export const g_path = (path='')=>`${PREFIX}${path}`;

export const parse_qs = ()=>url.qs_parse(document.location.search.slice(1));

export const stringify_qs = qs=>url.qs_str(qs);

export const get_referral_link = (base, code='')=>base+code;

export const get_location = ()=>
    encodeURIComponent(get_path(window.location.pathname));

export const go_to = target=>window.location = target;

export const INITIAL_QP = parse_qs();

export const LINKS = [{
    id: PLATFORMS.WINDOWS,
    title: 'Windows',
    direct: true,
    ver_required: true,
    icon: 'microsoft_logo',
    ref: (ver, mask=false)=>{
        if (mask)
            return `https://cdn.earnapp.com/static/earnapp-setup-${ver}.exe`;
        return `https://cdn.earnapp.com/static/earnapp-plus-setup-${ver}.exe`;
    }
}, {
    id: PLATFORMS.MACOS,
    title: 'MacOS (Beta)',
    direct: true,
    ver_required: true,
    icon: 'apple_logo',
    ref: ver=>`https://cdn.earnapp.com/static/earnapp-macos-${ver}.pkg`,
}, {
    id: PLATFORMS.IOS,
    title: 'iOS',
    icon: 'ios_logo',
    qr_ok: true,
    ref: ver=>
        'https://apps.apple.com/us/app/bright-rewards-earn-cash/id1645893250',
}, {
    id: PLATFORMS.LINUX,
    title: 'Linux',
    icon: 'linux_logo',
    ref: ()=>FAQ_LINK_INSTALL,
}, {
    id: PLATFORMS.PI,
    title: 'Raspberry Pi',
    icon: 'raspberry_pi_logo',
    ref: ()=>FAQ_LINK_INSTALL,
}, {
    id: PLATFORMS.ANDROID,
    title: 'Android',
    icon: 'android_logo',
    qr_ok: true,
    ref: ver=>`https://earnapp-by-brightdata.uptodown.com/android`,
}];

const paths = [{
    id: ROUTES.ME,
    label: 'Profile',
    to: g_path('/me'),
    ico: 'podium',
    exact: false,
    no_sidebar: true,
}, {
    id: ROUTES.OFFERS,
    label: 'Offers',
    to: g_path('/offers'),
    ico: 'offer',
    exact: false,
}, {
    id: ROUTES.CASHOUT,
    label: 'Cashout',
    to: g_path('/cashout'),
    ico: 'cashout',
    exact: true,
}, {
    id: ROUTES.REFERRALS,
    label: 'Referrals',
    to: g_path('/me/referrals'),
    ico: 'referral',
    exact: false,
}, {
    id: ROUTES.PASSIVE_INCOME,
    label: 'Easy profits',
    to: g_path('/me/passive-income'),
    ico: 'passive_income',
    exact: false,
}];

export const get_app_paths = ()=>paths.filter(r=>supports(r.feature));

export const get_dir = ()=>{
    const path = window.location.pathname;
    return path.split('/').pop();
};

export const need_no_auth_redirect = ()=>{
    const dir = get_dir();
    if (dir==ROUTES.OFFERS)
        return false;
    return true;
};
