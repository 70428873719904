// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {useStore} from '@nanostores/react';
import {$user, $loaded} from '../../store/user.js';
import {Button} from '../../comp/button';
import {Balance} from '../../comp/balance';
import {Removed_badge} from '../../comp/removed_badge';
import {User} from '../../comp/user';
import {Text} from '../../comp/text';
import {get_path, g_path} from '../../utils/path.js';
import logo_src from '../../img/earnapp_logo.svg';
import * as cns from './header.module.less';

export function Header({className}){
    const user = useStore($user);
    const loaded = useStore($loaded);
    return <div className={cn(cns.root, className)}>
      <div className={cns.container}>
        <a className={cns.logo_wrap} href="/">
          <img className={cns.logo} src={logo_src}/>
          {user?.is_root&&
            <Text.xs className={cns.roots} bold>for roots</Text.xs>}
        </a>
        <div className={cns.right}>
          {!!user.delete_ts&&<Removed_badge user={user}/>}
          {loaded
            ? <>
              <Balance compact/>
              <User user={user} to={get_path(g_path('/me'))}/>
            </>
            : <>
              <Button color_scheme="secondary" filled size="s"
                href={get_path(g_path('/signin'))}>
                Sign in
              </Button>
              <Button color_scheme="secondary" filled size="s"
                href={get_path(g_path('/signup'))}>
                Sign up
              </Button>
            </>
          }
        </div>
      </div>
    </div>;
}
