// LICENSE_CODE ZON
'use strict'; /*jslint node:true es9:true*/
const E = module.exports;

E.METHODS = {
    PAYPAL: 'paypal.com',
    AMAZON: 'amazon_generic',
    WISE: 'wise.com',
};
E.OAUTH_PROVIDER = {
    GOOGLE: 'google',
    APPLE: 'apple',
};
E.CP_PREFIX = '/dashboard';
