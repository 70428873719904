// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import {atom, onMount, computed} from 'nanostores';
import etask from '/util/etask.js';
import * as api from '../api.js';

export const $user = atom({});
export const $loading = atom(true);
export const $loaded = atom(false);
export const $is_root = computed($user, user=>{
    if (!user)
        return false;
    return !!user.is_root;
});

const fetch = ()=>etask(function*(){
    $loading.set(true);
    $loaded.set(false);
    try {
        const data = yield api.fetch_user();
        $user.set(data);
        $loaded.set(true);
    } finally {
        $loading.set(false);
    }
});

onMount($user, fetch);

export const refresh = ()=>fetch();
