// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Text} from '../text';
import {Icon} from '../icon';
import * as cns from './removed_badge.module.less';

export function Removed_badge({className, user}){
    let status;
    if (user.req_delete)
        status = 'is being deleted';
    else
        status = 'suspended';
    const msg = `Account ${status}, not earning`;
    return <div className={cn(cns.root, className)}>
      <Icon ico="warn" color="error" tip={msg}/>
      <Text.xs className={cns.text} error>
        {msg}
      </Text.xs>
    </div>;
}
