// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useMemo} from 'react';
import cn from 'classnames';
import {Tooltip} from '../tooltip';
import * as list from './list.js';
import * as cns from './icon.module.less';

const img_style = {display: 'inline-flex', width: '100%', height: '100%'};

function Img_inner({
    className,
    src,
    size,
    tip,
    tip_placement,
    ico,
})
{
    const style = useMemo(()=>{
        const res = {...img_style};
        res.height = size+'px';
        res.width = size+'px';
        return res;
    }, [size, img_style]);
    return <Tooltip tip={tip} placement={tip_placement}>
      <img className={className} style={style} src={src} data-ico={ico}/>
    </Tooltip>;
}

function Span_inner({className, src, tip, tip_placement, ico}){
    const style = useMemo(()=>({
        'WebkitMaskImage': `url("${src}")`,
    }), [src]);
    return <Tooltip tip={tip} placement={tip_placement}>
      <span className={cn(className)} style={style} data-ico={ico}/>
    </Tooltip>;
}

export function Icon({
    className,
    ico='default',
    size='m',
    color='light',
    as='span',
    src: in_src,
    tip,
    tip_placement,
    title,
})
{
    let src = in_src || list[ico];
    if (!src)
    {
        console.error(`[Icon] unknown svg ico - "${ico}"`);
        if (className)
            console.error(`[Icon] className - "${className}"`);
        src = '';
    }
    const is_img = as=='img';
    const classes = cn(cns.root, {
        [cns[`root__color_${color}`]]: is_img ? false : true,
        [cns[`root__size_${size}`]]: true,
        [cns.root__img]: is_img,
    }, className);
    const Comp = is_img ? Img_inner : Span_inner;
    return <Comp className={classes} src={src} ico={ico} tip={tip||title}
      tip_placement={tip_placement}/>;
}
