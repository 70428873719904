// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useState, useCallback, useMemo} from 'react';
import cn from 'classnames';
import {useStore} from '@nanostores/react';
import etask from '/util/etask.js';
import {is_valid_email} from '/util/url.js';
import {$is_root} from '../../store/user.js';
import store from '../../store.js';
import {METHODS, FEES_LINK} from '../../utils/constants.js';
import {fmt_money} from '../../utils/misc.js';
import {Text} from '../../comp/text';
import {Text_input} from '../../comp/text_input';
import {Button} from '../../comp/button';
import {Balance} from '../../comp/balance';
import {Dropdown} from '../../comp/dropdown';
import {Method_picker} from './method_picker.js';
import {is_amazon, use_amazons, get_pmethods, get_meta} from './util.js';
import * as cns from './setup.module.less';

function Fee_notice(){
    return <Text.xxs>
      *Note:{' '}
      <a target="_blank" rel="noreferrer noopener" href={FEES_LINK}>
        Handling fees are applied
      </a>
    </Text.xxs>;
}

function Balance_section(){
    return <div className={cn(cns.input_wrap, cns.input_wrap__row)}>
      <Text.s no_wrap tertiary>Your current balance:</Text.s>
      <Balance compact/>
    </div>;
}

function Withdraw_section({amount, min}){
    const tip = <Text.xxs>
      It's possible to redeem only the whole account balance now.
      {min ? ` You can not redeem less than $${min}` : ''}
    </Text.xxs>;
    return <>
      <div className={cn(cns.input_wrap, cns.input_wrap__row)}>
        <Text.s no_wrap tertiary>Withdraw:</Text.s>
        <Text.s mono tip={tip} tertiary>{fmt_money(amount)}</Text.s>
      </div>
    </>;
}

function Payment_section({amount, meta}){
    const tip = 'Estimated amount';
    const {percentage_fee, fixed_fee} = meta;
    let fee_value = 0;
    if (percentage_fee || fixed_fee)
        fee_value = percentage_fee ? amount*percentage_fee : fixed_fee;
    const payment = amount-fee_value;
    return <>
      {!!fee_value&&<div className={cn(cns.input_wrap, cns.input_wrap__row)}>
        <Text.s no_wrap tertiary>Fee:</Text.s>
        <Text.s mono tip={tip} tertiary>~{fmt_money(fee_value)}</Text.s>
      </div>}
      <div className={cn(cns.input_wrap, cns.input_wrap__row, cns.payment)}>
        <Text.l no_wrap bold>Payment:</Text.l>
        <Text.l className={cns.payment_value} mono tip={fee_value ? tip : ''}
          bold>
          {fee_value ? '~' : ''}
          {fmt_money(Math.max(payment, 0))}
        </Text.l>
      </div>
    </>;
}

function Redeem_form({
    on_submit,
    on_disable,
    val,
    autofocus=true,
    is_editor,
    meta={},
    bottom_content,
})
{
    const {min_redeem, value: method} = meta;
    const {email_label} = get_meta(meta);
    const [{redeem_details}] = store.use_money();
    const [loading, set_l] = useState(false);
    const initial_email = redeem_details?.email||'';
    const [email, set_email] = useState(initial_email);
    const [check, set_check] = useState(initial_email);
    const disabled = [
        email,
        method,
        email==check,
        is_valid_email(email),
        is_editor ? true : val>=min_redeem,
    ].some(v=>!v);
    const handle_submit = useCallback(()=>etask(function*(){
        this.finally(()=>set_l(false));
        set_l(true);
        yield on_submit?.({method, email});
    }), [method, email, on_submit]);
    const handle_disable = useCallback(()=>etask(function*(){
        this.finally(()=>set_l(false));
        set_l(true);
        yield on_disable?.();
    }), [on_disable]);
    return <>
      {!is_editor&&<Balance_section/>}
      <div className={cns.input_wrap}>
        <Text.s no_wrap tertiary>{email_label}{email_label ? ':' : ''}</Text.s>
        <Text_input value={email} className={cns.input}
          autofocus={autofocus} type="email" on_change={set_email}
          placeholder="e.g example@example.com"/>
      </div>
      <div className={cns.input_wrap}>
        <Text.s no_wrap tertiary>One more time:</Text.s>
        <Text_input value={check} className={cns.input}
          type="email" on_change={set_check} invalid={email&&email!=check}
          placeholder="e.g example@example.com"/>
      </div>
      {bottom_content}
      {!is_editor&&<>
        <Withdraw_section amount={val} min={min_redeem}/>
        <Payment_section amount={val} meta={meta}/>
      </>}
      <div className={cns.footer}>
        {method==METHODS.PAYPAL||method==METHODS.WISE ? <Fee_notice/> : null}
        <div className={cns.footer_row}>
          {is_editor&&redeem_details&&<Button size="l" disabled={disabled}
            className={cns.action} on_click={handle_disable} loading={loading}>
            Disable Auto-Redeem
          </Button>}
          <Button size="l" disabled={disabled} filled className={cns.action}
            on_click={handle_submit} loading={loading} color_scheme="primary"
            type="submit">
            {is_editor ? 'Save' : 'Withdraw'}
          </Button>
        </div>
      </div>
    </>;
}

function Amazon_adapter({val, on_submit, is_editor, method, meta, on_disable}){
    const list = use_amazons(meta);
    const [c, set_c] = useState(()=>list.includes(method) ? method : list[0]);
    const b_content = <div className={cns.input_wrap}>
      <Text.s no_wrap tertiary>Country:</Text.s>
      <Dropdown className={cns.input} items={list} on_change={set_c}
        value={c}/>
    </div>;
    return <Redeem_form meta={meta[c]} val={val} autofocus={is_editor}
      on_submit={on_submit} on_disable={on_disable} is_editor={is_editor}
      bottom_content={b_content}/>;
}

export function Setup({is_editor, preselect, on_submit, on_disable}){
    const [{balance, redeem_details}] = store.use_money();
    const [methods_params] = store.use_methods();
    const is_root = useStore($is_root);
    const methods = useMemo(()=>get_pmethods(methods_params, is_root),
        [methods_params, is_root]);
    const [method, set_method] = useState(()=>{
        if (preselect)
            return preselect;
        const m = redeem_details?.payment_method;
        if (!m)
            return methods[0]?.id;
        return is_amazon(m) ? METHODS.AMAZON : m;
    });
    const props = {
        val: balance||0,
        on_submit,
        on_disable,
        is_editor,
        key: method,
    };
    return <form className={cns.root}>
      <Text.xl>Receive via:</Text.xl>
      <Method_picker value={method} items={methods} on_change={set_method}
        meta={methods_params}/>
      {is_amazon(method)
        ? <Amazon_adapter {...props} meta={methods_params}/>
        : <Redeem_form {...props} meta={methods_params[method]}/>
      }
    </form>;
}
