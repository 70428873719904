// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Button} from '../button';

export function Oauth_btn({
    className,
    children,
    logo='',
    url,
    disabled,
    size='m',
})
{
    return <Button href={url} className={cn('ea_oauth_btn', className)}
      in_new_tab={false} color_scheme="primary" filled standard_link
      disabled={disabled} size={size} ico={logo} bold>
      {children}
    </Button>;
}
