// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useMemo} from 'react';
import cn from 'classnames';
import {useLocation} from 'react-router-dom';
import {get_path, get_app_paths} from '../../utils/path.js';
import {Button} from '../button';
import './sidebar.less';

const is_active = (path, item)=>{
    if (item.exact)
        return path==item.to;
    return path.startsWith(item.to);
};

export function Sidebar({className}){
    const {pathname} = useLocation();
    const items = useMemo(()=>get_app_paths(), []);
    return <aside className={cn('ea_sidebar', className)}>
      <ul className="eas_list">
        {items.map(i=>{
            const active = is_active(pathname, i);
            if (i.no_sidebar)
                return null;
            return <li key={i.id} className={cn('eas_item',
              i.no_mobile&&'eas_desktop_only')}>
              <Button ico={i.ico} href={get_path(i.to)}
                className="eas_button" align="left" size="m"
                color_scheme={active ? 'primary' : 'tertiary'}
                view={active ? 'button' : 'link'} filled={active}>
                {i.label}
              </Button>
            </li>;
        })}
      </ul>
    </aside>;
}
