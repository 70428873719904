// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useCallback} from 'react';
import etask from '/util/etask.js';
import store from '../../store.js';
import * as api from '../../api.js';
import {alert} from '../../comp/alert';
import {confirm} from '../../comp/confirm';
import {Setup} from './setup.js';

export function Redeem({is_editor, on_submit, preselected_method}){
    const handle_redeem = useCallback(data=>etask(function*(){
        const {method, email} = data;
        let res;
        try {
            const api_method = is_editor ? api.set_redeem_details : api.redeem;
            res = yield api_method(email, method);
        } catch(e){
            res = {error: e.message};
        }
        if (res.error)
        {
            return void alert({
                title: is_editor ? `Can't update settings` : 'Redeem failed',
                text: res.error,
                negative: true,
            });
        }
        alert({
            text: is_editor
                ? 'Auto-redeem settings successfully updated'
                : 'Redeem request successfully created',
            positive: true,
        });
        if (!is_editor)
        {
            store.use_devices.invalidate();
            store.use_transactions.invalidate();
        }
        store.use_money.invalidate();
        on_submit?.();
    }), [on_submit, is_editor]);
    const handle_disable = useCallback(()=>etask(function*(){
        const confirmed = yield confirm();
        if (!confirmed)
            return;
        let res;
        try {
            res = yield api.disable_redeem();
        } catch(e){
            res = {error: e.message};
        }
        if (res.error)
        {
            alert({
                title: 'Error while disabling auto-redeem',
                text: res.error,
                negative: true,
            });
            return false;
        }
        store.use_money.invalidate();
        alert({text: 'Auto-redeem successfully disabled', positive: true});
    }), []);
    return <Setup is_editor={is_editor} on_submit={handle_redeem}
      on_disable={handle_disable} preselect={preselected_method}/>;
}
