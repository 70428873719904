// LICENSE_CODE ZON
'use strict'; /*jslint browser:true, es9:true*/
import cookie from 'cookie';
import etask from '/util/etask.js';
import ajax from '/util/ajax.js';
import {g_path, get_location, parse_qs,
    need_no_auth_redirect} from './utils/path.js';
import {APP_ID, VERSION, STORAGE_KEYS, XSRF_COOKIE, XSRF_HEADER,
    DOMAIN} from './utils/constants.js';
import * as local_storage from './utils/local_storage.js';

const ENDPOINTS = {
    USER_DATA: g_path('/api/user_data'),
    DELETE_USER: g_path('/api/user/request_delete'),
    UNDELETE_USER: g_path('/api/user/request_delete_cancel'),
    USER: g_path('/api/user'),
    RESTORE_USER: g_path('/api/restore_user'),
    DEVICES: g_path('/api/devices'),
    BONUSES: g_path('/api/bonuses'),
    MONEY: g_path('/api/money'),
    DEVICE: g_path('/api/device/'),
    LINK_DEVICE: g_path('/api/link_device'),
    EDIT_DEVICE: g_path('/api/edit_device/'),
    SHOW_DEVICE: g_path('/api/show_device'),
    HIDE_DEVICE: g_path('/api/hide_device'),
    ONBOARDING: g_path('/api/onboarding'),
    TOKEN: g_path('/api/token'),
    LOGOUT: g_path('/api/logout'),
    REDEEM: g_path('/api/redeem'),
    REFEREES: g_path('/api/referees'),
    REFEREES_BVPN: g_path('/api/referees_bvpn'),
    COUNTERS: g_path('/api/counters'),
    NOTIFS: g_path('/api/notifications'),
    REDEEM_DETAILS: g_path('/api/redeem_details'),
    TRANSACTIONS: g_path('/api/transactions'),
    DOWNLOADS: g_path('/api/downloads'),
    P_METHODS: g_path('/api/payment_methods'),
    USAGE: g_path('/api/usage'),
    DEVICE_STATUSES: g_path('/api/device_statuses'),
    // auth
    SIGNIN_URL: provider=>g_path(`/api/auth/${provider}/signin`),
    SIGNUP_URL: provider=>g_path(`/api/auth/${provider}/signup`),
    // root
    HAS_USER: g_path('/api/has_user'),
    USER_STATUS: g_path('/api/user/status'),
    RAW_MONGO_DEVICE: g_path('/api/raw_mongo_device/'),
    // misc
    XSRF: g_path('/api/sec/rotate_xsrf'),
    ZEN_SSO: 'https://earnapp.com/dashboard/api/zendesk_sso',
    OFW: g_path('/api/offerwall'),
    OFW_PAGE: g_path('/api/offerwall_page'),
    OFW_STATUS: g_path('/api/offerwall/status_web'),
};
const POST = 'post';
const PUT = 'put';
const GET = 'get';
const DELETE = 'delete';
const ABORT_LABEL = '$aborted$';

const ALLOWED_QP = [];

const get_query_params = (params={})=>{
    const rec = {appid: APP_ID, version: VERSION, ...params};
    const user = local_storage.read(STORAGE_KEYS.USER);
    const qs_params = parse_qs();
    for (const p of ALLOWED_QP)
    {
        if (qs_params[p])
            rec[p] = qs_params[p];
    }
    if (user)
        rec.u = user;
    return rec;
};

ajax.events.on('unauthorized', ()=>{
    if (need_no_auth_redirect())
        document.location = g_path(`/signin?redirect=${get_location()}`);
});

ajax.events.on('maintenance', ()=>{
    document.location = g_path('/maintenance');
});

const send = method=>(url, opts={})=>{
    const {qp, data} = opts;
    const query_params = get_query_params(qp);
    let xsrf_token;
    if (data && XSRF_HEADER in data)
    {
        xsrf_token = opts.data[XSRF_HEADER];
        delete opts.data[XSRF_HEADER];
    }
    else
        xsrf_token = cookie.get(XSRF_COOKIE);
    const headers = {
        [XSRF_HEADER]: xsrf_token,
    };
    return ajax[method]({
        url,
        qs: query_params,
        data,
        headers,
        abort_value: ABORT_LABEL,
    });
};

export const fetch_user = ()=>etask(function*(){
    const res = yield send(GET)(ENDPOINTS.USER_DATA);
    if (res.error)
        return res;
    return {
        email: res.email,
        name: (res.name||'').trim(),
        first_name: (res.first_name||'').trim(),
        last_name: (res.last_name||'').trim(),
        picture: res.picture||'',
        locale: res.locale||'en',
        joined_ts: res.joined_ts,
        referral_code: res.referral_code||'',
        onboarding: res.onboarding||'',
        delete_ts: res.delete_ts||'',
        is_root: res.is_root||false,
        suspend_reason: res.suspend_reason||'',
        req_delete: res.req_delete||'',
        bvpn_ref_enabled: res.bvpn_ref_enabled||false,
    };
});

export const delete_account = ()=>send(PUT)(ENDPOINTS.DELETE_USER);
export const undelete_account = ()=>send(PUT)(ENDPOINTS.UNDELETE_USER);

export const fetch_transactions = ()=>send(GET)(ENDPOINTS.TRANSACTIONS);

export const fetch_devices = ()=>send(GET)(ENDPOINTS.DEVICES);

export const fetch_money = ()=>send(GET)(ENDPOINTS.MONEY);

export const link_device = ({code, platform})=>
    send(POST)(ENDPOINTS.LINK_DEVICE, {data: {uuid: code, platform}});

export const edit_device = (uuid, data)=>
    send(PUT)(ENDPOINTS.EDIT_DEVICE+uuid, {data});

export const raw_mongo_device = uuid=>
    send(GET)(ENDPOINTS.RAW_MONGO_DEVICE+uuid);

export const toggle_device = (uuid, show)=>{
    const endpoint = show ? ENDPOINTS.SHOW_DEVICE : ENDPOINTS.HIDE_DEVICE;
    return send(PUT)(endpoint, {data: {uuid}});
};

export const delete_device = uuid=>send(DELETE)(ENDPOINTS.DEVICE+uuid);

export const exchange_token = data=>send(POST)(ENDPOINTS.TOKEN, {data});

export const logout = ()=>send(POST)(ENDPOINTS.LOGOUT);

export const redeem = (to, payment_method)=>send(POST)(ENDPOINTS.REDEEM, {
    data: {to, payment_method},
});

export const set_redeem_details = (to, payment_method)=>
    send(POST)(ENDPOINTS.REDEEM_DETAILS, {data: {to, payment_method}});

export const disable_redeem = ()=>send(DELETE)(ENDPOINTS.REDEEM_DETAILS);

export const fetch_downloads = ()=>send(GET)(ENDPOINTS.DOWNLOADS);

export const get_usage = (step='daily')=>
    send(GET)(ENDPOINTS.USAGE, {qp: {step}});

export const fetch_payment_methods = ()=>send(GET)(ENDPOINTS.P_METHODS);

export const fetch_referees =
    (page=0)=>send(GET)(ENDPOINTS.REFEREES, {qp: {page}});

export const fetch_referees_bvpn = (page=0)=>send(GET)(ENDPOINTS.REFEREES_BVPN,
    {qp: {page}});

export const fetch_counters = ()=>send(GET)(ENDPOINTS.COUNTERS);

export const fetch_notifs = ()=>etask(function*(){
    const r = yield send(GET)(ENDPOINTS.NOTIFS);
    if (r.error)
        return r;
    return {list: r};
});

export const fetch_bonuses = ()=>etask(function*(){
    const r = yield send(GET)(ENDPOINTS.BONUSES);
    if (r.error)
        return r;
    return {list: r};
});

export const mark_onboarding = ()=>send(PUT)(ENDPOINTS.ONBOARDING);

export const has_user = search=>send(GET)(ENDPOINTS.HAS_USER, {qp: {search}});

export const update_user_status = (email, action, suspend_reason, with_email)=>
    send(PUT)(ENDPOINTS.USER_STATUS, {qp: {email, action, suspend_reason,
    with_email}});

export const rotate_xsrf = ()=>send(GET)(ENDPOINTS.XSRF);

export const get_device_statuses = devices=>
    send(POST)(ENDPOINTS.DEVICE_STATUSES, {data: {devices}});

export const get_signin_url = provider=>
    `https://${DOMAIN}${ENDPOINTS.SIGNIN_URL(provider)}`;

export const get_signup_url = provider=>
    `https://${DOMAIN}${ENDPOINTS.SIGNUP_URL(provider)}`;

export const get_zendesk_sso = return_to=>send(GET)(ENDPOINTS.ZEN_SSO
    +'?return_to='+return_to);

const collect_env_qp = ()=>etask(function*(){
    const agent = encodeURIComponent(navigator.userAgent);
    let hints = '';
    if (navigator.userAgentData)
    {
        try {
            hints = yield navigator.userAgentData.getHighEntropyValues(
              ['architecture', 'bitness', 'brands', 'mobile', 'model',
              'platform', 'platformVersion', 'uaFullVersion',
              'fullVersionList', 'wow64']);
            hints = encodeURIComponent(JSON.stringify(hints));
        } catch(e){
          console.log(e);
        }
    }
    return {agent, hints};
});

export const fetch_offers = ()=>etask(function*(){
    const qp = {
        ...yield collect_env_qp(),
        category: 'all',
        offer_type: 'web',
    };
    const res = yield send(GET)(ENDPOINTS.OFW, {qp});
    if (res.error)
        return res;
    return res;
});

const get_offers_query_params = (params={})=>{
    const {page=0, size=10} = params;
    const qp = {
        offer_type: 'web',
        page,
        size,
    };
    if (params.order_by)
        qp.order_by = params.order_by;
    if (params.category)
        qp.category = params.category;
    if (params.name)
        qp.name = params.name;
    if (params.platforms)
        qp.platforms = params.platforms;
    if (params.status)
        qp.status = params.status;
    return qp;
};

export const fetch_offers_page = (params={})=>etask(function*(){
    const qp = get_offers_query_params(params);
    const res = yield send(GET)(ENDPOINTS.OFW_PAGE, {qp});
    if (res.error)
        return res;
    return res;
});

export const fetch_offers_status = (params={})=>etask(function*(){
    const qp = get_offers_query_params(params);
    const res = yield send(GET)(ENDPOINTS.OFW_STATUS, {qp});
    if (res.error)
        return res;
    return res;
});

export const abort = task=>ajax.abort(task.operation);

export const is_aborted = res=>res===ABORT_LABEL;

