// extracted by mini-css-extract-plugin
var _1 = "OHCMqH2LMK3Hl82JzT8k";
var _2 = "XGbpJAxy1AEhEYtASbpp";
var _3 = "JsBi0QJm7z6p_GsYHObV";
var _4 = "SJiPwSQNW8P3a5iESIF2";
var _5 = "CMjKuex0dBYzxC03C6ju";
var _6 = "_vYNyIEHlb84QIhMsvwT";
var _7 = "mO_TsKHEnUIKru43USRz";
var _8 = "b6Nm_12ibeKhxK3AicBw";
var _9 = "futRaWLpNbStIt83j62M";
var _a = "jnp2k63IQUd_k5gYwTqm";
var _b = "Cdjh9gvnvwr2ZRVEHKgb";
var _c = "GHyvQiuq4kMzalSIaMq5";
var _d = "UM9JbXu88cRmphVl0RvY";
var _e = "VARERRCaSyIp8HqFU8zR";
var _f = "zHit501rIYzlHlX2QAbB";
var _10 = "VFKo4261DUaosFcgtmrg";
var _11 = "cZjibE4Cha8tXi5U59Z0";
var _12 = "aexr79kMvf8IQZLJeIU8";
var _13 = "RST5riMOddfOmjzEW68z";
var _14 = "bz5mXI5QkmwD40mOFJvw";
export { _1 as "root", _2 as "root__color_accent", _3 as "root__color_dark", _4 as "root__color_default", _5 as "root__color_error", _6 as "root__color_light", _7 as "root__color_primary", _8 as "root__color_secondary", _9 as "root__color_success", _a as "root__color_warning", _b as "root__color_white", _c as "root__img", _d as "root__size_huge", _e as "root__size_l", _f as "root__size_m", _10 as "root__size_s", _11 as "root__size_xl", _12 as "root__size_xs", _13 as "root__size_xxl", _14 as "root__size_xxs" }
