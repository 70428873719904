// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import {ms, dur_to_str} from '/util/date.js';
import {rand_range} from '/util/rand.js';
import store from '../../store.js';
import {Text} from '../text';

const on_expire = counter=>{
    setTimeout(()=>{
        store.use_counters.invalidate();
    }, ms.MS*rand_range(50, 2000));
    switch (counter)
    {
    case 'auto_redeem':
        setTimeout(()=>{
            store.use_money.invalidate();
            store.use_devices.invalidate();
            store.use_referees.invalidate();
            store.use_transactions.invalidate();
        }, ms.SEC*rand_range(30, 180));
    case 'balance_sync':
        setTimeout(()=>{
            store.use_money.invalidate();
            store.use_devices.invalidate();
        }, ms.SEC*rand_range(30, 180));
    }
};

const get_upr_freq = (t, u)=>{
    let fr = ms.SEC;
    if (u>1)
        return fr;
    else if (t-ms.HOUR-ms.MIN>ms.MIN)
        fr = ms.MIN;
    return fr;
};

export function Countdown({counter, units=1, size='m', className}){
    const [data] = store.use_counters();
    const _time = data[counter]||0;
    const [time, set_time] = useState(_time);
    useEffect(()=>{
        set_time(_time);
        if (_time>ms.DAY||_time==0)
            return;
        let upd = get_upr_freq(_time, units);
        let interval;
        const reduce_time = ()=>set_time(t=>{
            const nt = Math.max(t-upd, 0);
            const fr = get_upr_freq(nt, units);
            if (fr!=upd)
            {
                upd = fr;
                clearInterval(interval);
                interval = setInterval(reduce_time, upd);
            }
            if (nt==0)
            {
                clearInterval(interval);
                on_expire(counter);
            }
            return nt;
        });
        interval = setInterval(reduce_time, upd);
        return ()=>clearInterval(interval);
    }, [_time, units, counter]);
    const Container = Text[size];
    return <Container span mono bold className={cn('ea_countdown', className)}>
      {dur_to_str(time, {sep: ' ', units, precision: 1000})}</Container>;
}
