// extracted by mini-css-extract-plugin
var _1 = "IkfLLu5oFXWm6HrZcylC";
var _2 = "GnoWXBFGuDgELsJuOzYb";
var _3 = "uuUedEenWK0RSws2b0Ed";
var _4 = "wPlIrUwqK9rmabxr1u40";
var _5 = "vIYTb3MCNkULl654tTix";
var _6 = "x69OAXGaV5v5ou8xA5x7";
var _7 = "snN9GxqIHlQpujjhJgVk";
var _8 = "sCsK9GdvJdJAW2Y5nHhw";
var _9 = "sb2YhrcrGAMGOsTOVeAD";
var _a = "EVtR2I2UQdn5c2Psrb7b";
var _b = "DTTMcTpAGWaH1k84VPag";
var _c = "efl5Im6T3uCfPmfSHsRW";
var _d = "pb55ITVEmFjaauNeBy1k";
var _e = "QjeSfm2_AWv6SufxlCdL";
var _f = "oNMRi4EUajRJXg8Ol9p_";
var _10 = "RUXe6MY7BHzwN3JVJOfr";
var _11 = "kPEdmOEfAnFju_38UY56";
var _12 = "c_ZeWmJEz7GO6MhEBDfx";
var _13 = "CNx5o7jRS9zeBzrPpTfw";
var _14 = "XJRWJkVCVyjHZhAnRWBN";
var _15 = "emmTOXIZZUo8l94zFBX0";
var _16 = "ptErwTQBCJy6MB52e9G7";
var _17 = "RGw6Jqb_7IYhyY0BzR6B";
var _18 = "BZqEL_7NRvvvqZmNOLpf";
export { _1 as "root", _2 as "root__accent", _3 as "root__bold", _4 as "root__disabled", _5 as "root__error", _6 as "root__ghost", _7 as "root__light", _8 as "root__mono", _9 as "root__no_wrap", _a as "root__primary", _b as "root__secondary", _c as "root__size_l", _d as "root__size_m", _e as "root__size_s", _f as "root__size_xl", _10 as "root__size_xs", _11 as "root__size_xxl", _12 as "root__size_xxs", _13 as "root__size_xxxl", _14 as "root__size_xxxs", _15 as "root__success", _16 as "root__tertiary", _17 as "root__warn", _18 as "root__white" }
