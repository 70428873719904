// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useCallback} from 'react';
import cn from 'classnames';
import {P_METHOD_META} from '../../utils/constants.js';
import {get_min_redeem} from './util.js';
import {Method_card} from './method_card.js';
import * as cns from './method_picker.module.less';

const default_render = props=><Method_card {...props}/>;

function Item({item, active, on_click, add_meta, render_item=default_render}){
    const meta = P_METHOD_META[item.id];
    const handle_click = useCallback(()=>
        on_click?.(item.id), [item.id]);
    const min_val = add_meta ? get_min_redeem(item.id, add_meta) : null;
    const min = min_val ? `min $${min_val}` : null;
    return render_item({
        method_id: item.id,
        on_click: handle_click,
        disabled: item.disabled,
        active: active,
        ico: meta.ico,
        label: item.title,
        description: min,
    });
}

export function Method_picker({
    className,
    value,
    items,
    on_change,
    meta,
    render_item,
    extra_items=[],
    all_active=false,
})
{
    return <ul className={cn(cns.list, className)}>
      {items.map(m=><li key={m.id} className={cns.item}>
        <Item item={m} on_click={on_change} active={all_active||m.id==value}
          add_meta={meta} render_item={render_item}/>
      </li>)}
      {extra_items.map((item, i)=>
        <li className={cns.item} key={i}>{item}</li>)}
    </ul>;
}
