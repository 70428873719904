// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useCallback, useState} from 'react';
import {Button} from '../../comp/button';
import {Popup} from '../../comp/popup';
import {Redeem} from './redeem';

function Default_trigger({className, ico, tip, on_click}){
    return <Button className={className} ico={ico} size="xs" filled
      color_scheme="tertiary" tip={tip} tip_placement="right"
      on_click={on_click}/>;
}

export function Popup_btn({
    className,
    is_redeem,
    trigger_comp,
    trigger_props,
    preselected_method,
})
{
    const [show_popup, set_show_popup] = useState(false);
    const toggle_popup = useCallback(()=>set_show_popup(s=>!s), []);
    const title = is_redeem ? 'Withdraw balance' : 'Auto-redeem';
    const ico = is_redeem ? 'cashout' : 'edit';
    const tip = is_redeem ? 'Withdraw now' : 'Edit';
    const Btn = trigger_comp ? trigger_comp : Default_trigger;
    return <>
      <Btn className={className} ico={ico} tip={tip} {...trigger_props}
        on_click={toggle_popup}/>
      <Popup title={title} show={show_popup}
        on_close={toggle_popup} hide_actions>
        <Redeem on_submit={toggle_popup} is_editor={!is_redeem}
          preselected_method={preselected_method}/>
      </Popup>
    </>;
}
