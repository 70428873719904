// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import * as oauth from '../../utils/oauth.js';
import {g_path, get_path, go_to} from '../../utils/path.js';
import {use_instant_redirect} from '../../utils/hooks.js';
import {Panel} from '../../comp/panel';
import {Text} from '../../comp/text';
import {Page} from '../../comp/page';
import {Oauth_btn} from '../../comp/oauth_btn';
import './signup.less';

function Earn_block(){
    return <div className="eas_earn">
      <Text.xxl h1 bold className="eas_earn_title">
        Earn passive income while your devices rest</Text.xxl>
      <Text.xl className="eas_earn_description">
        Make big money, safely, by sharing the unused resources on your resting
        devices
      </Text.xl>
    </div>;
}

function Howto_block(){
    return <div className="eas_howto">
      <Text.xxl h2 bold className="eas_how_title">How does it work?</Text.xxl>
      <ul className="eas_steps">
        <li className="eas_step eas_step_download">
          <Text.m>Download</Text.m></li>
        <li className="eas_step eas_step_install">
          <Text.m>Install</Text.m></li>
        <li className="eas_step eas_step_earn">
          <Text.m>Earn</Text.m></li>
      </ul>
    </div>;
}

function Signup_block({className, meta}){
    return <Panel className={cn('eas_signup', className)} no_background
      no_border>
      <Text.m className="eas_title" h2 bold>{meta.action_label}</Text.m>
      <div className="eas_separator"/>
      <ul className="eas_actions">
        {meta.google.enabled&&<li className="eas_action">
          <Oauth_btn url={meta.google.url} logo="google_logo" size="l">
            {meta.action_label} with Google
          </Oauth_btn>
        </li>}
        {meta.apple.enabled&&<li className="eas_action">
          <Oauth_btn url={meta.apple.url} logo="apple_logo" size="l">
            {meta.action_label} with Apple
          </Oauth_btn>
        </li>}
      </ul>
      <div className="eas_switch">
        <Text.s className="eas_qestion">Already have an account?</Text.s>
        <Link className="eas_link" to={get_path(g_path('/signin'))}>
          Sign in
        </Link>
      </div>
    </Panel>;
}

export function Signup(){
    const meta = oauth.get_meta(true);
    const redirect = use_instant_redirect(meta);
    if (redirect)
        return void go_to(redirect);
    return <Page className="ea_signup" sidebarless headerless has_logo>
      <div className="eas_row">
        <Signup_block meta={meta}/>
        <Earn_block/>
      </div>
      <Howto_block/>
      <div className="eas_signup_double_wrapper">
        <Signup_block className="eas_signup_double" meta={meta}/>
      </div>
    </Page>;
}
