// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useState, useImperativeHandle, useCallback} from 'react';
import react_dom from 'react-dom';
import etask from '/util/etask.js';
import {Text} from '../text/text';
import {Popup, CLOSE_ANIMATION_DURATION} from '../popup';
import * as cns from './confirm.module.less';

const dummy_elem = document.createElement('div');
dummy_elem.classList.add('ea-confirm');
document.body.appendChild(dummy_elem);

function Confirm({show, et, title, text, confirm_label, cancel_label}){
    const ok_label = confirm_label || 'OK';
    const not_ok_label = cancel_label || 'Cancel';
    const popup_title = title || 'Confirm';
    const confirm = useCallback(()=>et?.return?.(true), [et]);
    const close = useCallback(()=>et?.return(false), [et]);
    return <Popup title={popup_title} show={show} on_close={close}
      on_ok={confirm} submit_label={ok_label} cancel_label={not_ok_label}
      reverse_btn_colors has_shadow>
      <div className={cns.content}>
        {text ? text : <Text.s>Are you sure?</Text.s>}
      </div>
    </Popup>;
}

const Confirm_wrap = React.forwardRef(function Wrap(_, ref){
    const [show, set_show] = useState(false);
    const [et, set_et] = useState(null);
    const [params, set_params] = useState({});
    useImperativeHandle(ref, ()=>({
        set_show,
        set_et,
        set_params,
    }), []);
    return <Confirm show={show} et={et} title={params.title} text={params.text}
      confirm_label={params.confirm_label}
      cancel_label={params.cancel_label}/>;
});

let wrap_ref = null;
react_dom.render(<Confirm_wrap ref={ref=>wrap_ref = ref}/>, dummy_elem);

export const confirm = ({
    title,
    text,
    confirm_label,
    cancel_label
}={})=>
etask(function(){
    this.finally(()=>{
        wrap_ref.set_et(null);
        wrap_ref.set_show(false);
        setTimeout(()=>wrap_ref.set_params({}), CLOSE_ANIMATION_DURATION+10);
    });
    wrap_ref.set_params({title, text, confirm_label, cancel_label});
    wrap_ref.set_et(this);
    wrap_ref.set_show(true);
    return this.wait();
});
