// LICENSE_CODE ZON
'use strict'; /*jslint react:true es6:true*/
import React, {useCallback, useRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import {useStore} from '@nanostores/react';
import {$is_mobile} from '../../store/is_mobile.js';
import {Tooltip} from '../tooltip/tooltip';
import {Text} from '../text/text';
import {Icon} from '../icon';
import {NEW_TAB_ATTRS} from '../../utils/constants.js';
import * as cns from './button.module.less';

const get_sizes = btn_size=>{
    return {
        xs: {icon: 'xs', text: 'xs'},
        s: {icon: 'xs', text: 's'},
        m: {icon: 'm', text: 'm'},
        l: {icon: 'l', text: 'l'},
    }[btn_size];
};

const get_colors = ({color_scheme, is_link, cs_selected, filled})=>{
    return {
        main: {
            icon: {color: 'white'},
            text: {white: is_link && !cs_selected},
        },
        secondary: {
            icon: {color: filled ? 'white' : 'light'},
            text: {
                white: !filled,
                accent: filled,
            },
        },
        tertiary: {
            icon: {color: filled ? 'white' : 'light'},
            text: {
                secondary: true,
            },
        },
        primary: {
            icon: {color: filled ? 'white' : 'primary'},
            text: {
                primary: !filled,
                white: filled,
            },
        },
        accent: {
            icon: {color: filled ? 'white' : 'accent'},
            text: {
                accent: !filled,
                white: filled,
            },
        },
        error: {
            icon: {color: filled ? 'white' : 'error'},
            text: {
                error: !filled,
                white: filled,
            },
        },
        success: {
            icon: {color: filled ? 'white' : 'success_dark'},
            text: {
                success: !filled,
                white: filled,
            },
        },
    }[color_scheme];
};

const get_ico = ({icon, sizes, color, rotate})=>{
    let r_class = rotate ? cns[`icon__rotate_${rotate}`] : undefined;
    return <Icon className={cn(cns.icon, r_class)} ico={icon}
      size={sizes.icon} color={color.color}/>;
};

export function Button({
    id,
    className,
    on_click,
    children,
    ico,
    type='button',
    color_scheme: cs_passed,
    filled=false,
    round,
    view='button',
    align='center',
    href,
    in_new_tab: use_new_tab,
    hide_new_tab_ico,
    standard_link,
    download,
    size='m',
    disabled,
    loading,
    tip: tt='',
    tip_placement='bottom',
    tip_animation,
    tip_show_delay_s=1,
    dot_count=0,
    confirm: confirm_opt,
    slot_right,
    auto_focus,
    test_id,
    left_shift,
    right_shift,
    rotate_ico,
    bold,
})
{
    const cs = cs_passed || 'main';
    const cs_selected = !!cs_passed;
    const handle_click = useCallback(event=>{
        if (!href && type!='submit')
            event.preventDefault();
        on_click?.();
    }, [on_click, confirm_opt, href, type]);
    const color_scheme = cs;
    const is_link_view = view=='link';
    const inner_filled = is_link_view ? false : filled;
    const {
        icon: i_color,
        text: t_color,
    } = get_colors({
        color_scheme,
        is_link: is_link_view,
        cs_selected,
        filled: inner_filled,
    });
    const sizes = get_sizes(size);
    const Text_comp = Text[sizes.text];
    let tip = tt;
    if (disabled&&!loading)
        tip = `Disabled${tt ? ': ' : ''}${tt}`;
    if (loading)
        tip = 'Loading...';
    const classes = cn(cns.wrap, cns[`wrap__size_${size}`],
        cns[`wrap__cs_${color_scheme}`], {
        [cns.wrap__filled]: inner_filled,
        [cns.wrap__round]: round,
        [cns.wrap__disabled]: disabled,
        [cns.wrap__loading]: loading,
        [cns.wrap__link]: view=='link',
        [cns.wrap__no_text]: !children,
        [cns.wrap__left_shift]: left_shift,
        [cns.wrap__right_shift]: right_shift,
    }, className);
    const dis = disabled||loading;
    const icon = loading ? 'ico_spinner' : ico;
    const is_link = !!href;
    const is_local_link = is_link&&!standard_link&&!!href?.startsWith('/');
    const Element = href
        ? is_local_link ? Link : 'a'
        : 'button';
    const opts = {
        onClick: handle_click,
    };
    let in_new_tab = typeof use_new_tab =='boolean'
        ? use_new_tab
        : is_link&&!is_local_link;
    if (is_link && !dis)
    {
        if (is_local_link)
            opts.to = href;
        else
            opts.href = href;
        if (download)
            opts.download = true;
        if (download||in_new_tab)
            Object.assign(opts, NEW_TAB_ATTRS);
    }
    else
        opts.type = type;
    const ref = useRef(null);
    useEffect(()=>{
        if (auto_focus)
            requestAnimationFrame(()=>ref?.current?.focus());
    }, [auto_focus]);
    if (test_id)
        opts['data-test-id'] = test_id;
    const is_mobile = useStore($is_mobile);
    const t = is_mobile ? null : tip;
    return <Tooltip tip={t} placement={tip_placement} animation={tip_animation}
      show_delay_s={tip_show_delay_s}>
      <Element className={classes} disabled={dis} id={id} ref={ref}
        {...opts}>
        {!!dot_count&&<Text.xxs className={cns.dot} bold mono>
          {dot_count > 1 ? dot_count : ''}
        </Text.xxs>}
        {!!loading && !ico&&<Icon ico="spinner"
          color="main" size="s" className={cns.loading}/>}
        <div className={cn(cns.inner, cns[`inner__align_${align}`])}>
          {!!ico&&get_ico({icon, sizes, color: i_color, rotate: rotate_ico})}
          {!!children&&<Text_comp className={cns.text} bold={bold}
            {...t_color}>
            {children}
          </Text_comp>}
          {slot_right&&<>
            <div className={cns.spacer}/>
            {slot_right}
          </>}
          {!hide_new_tab_ico && in_new_tab&&<>
            <div className={cns.spacer}/>
            <Icon className={cn(cns.icon, cns.icon__new_tab)}
              ico="open" size="xxs" color={i_color.color}/>
          </>}
        </div>
      </Element>
    </Tooltip>;
}
