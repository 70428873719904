// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import * as cns from './panel.module.less';

function Panel_comp({
    as='div',
    children,
    className,
    no_border,
    no_padding,
    elevated,
    no_background,
    style = {}
}, ref,
)
{
    const Wrapper = as;
    const classnames = cn(cns.root, {
        [cns.root__no_border]: no_border,
        [cns.root__elevated]: elevated,
        [cns.root__no_padding]: no_padding,
        [cns.root__no_background]: no_background,
    }, className);
    return <Wrapper ref={ref} className={classnames} style={style}>
      {children}
    </Wrapper>;
}

export const Panel = React.forwardRef(Panel_comp);
