// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import conv from '/util/conv.js';
import {OFFER_STATUS} from './constants.js';

export const fmt_money = (v, dec=2)=>{
    const ret = '$'+(+v||0).toFixed(dec);
    return ret.endsWith('.'+'0'.repeat(dec)) ? ret.slice(0, -dec-1) : ret;
};

export const bw_to_readable =
    bw=>(conv.scaled_number(bw, {base: 1024})||0)+'B';

export const is_offer_started = offer=>
    offer.status==OFFER_STATUS.STARTED
    || offer.status==OFFER_STATUS.IN_PROGRESS;

export const is_offer_done = offer=>offer.status==OFFER_STATUS.COMPLETED;

export const limit_txt = (txt, limit=10)=>{
    if (!txt)
        return txt;
    if (txt.length-limit>3)
        return `${txt.slice(0, limit)}...`;
    return txt;
};
