// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {
    useState,
    useEffect,
    useLayoutEffect,
    useCallback,
    useRef,
} from 'react';
import {createPortal} from 'react-dom';
import cn from 'classnames';
import {Text} from '../text';
import {Panel} from '../panel';
import {Button} from '../button';
import './popup.less';

export const CLOSE_ANIMATION_DURATION = 150;
export const CONTAINER_CLASS = 'ea_popups';

const ACTIVE_POPUP_CLASS = 'ea-popup--active';

const container = document.createElement('div');
container.classList.add(CONTAINER_CLASS);
document.body.appendChild(container);
let body_locked = false;

const lock_body = ()=>{
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.classList.toggle(ACTIVE_POPUP_CLASS, true);
    body_locked = true;
};

const unlock_body = ()=>{
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    document.body.style.width = '';
    window.scrollTo({
        top: -1 * parseInt(scrollY || '0', 10),
        behavior: 'instant',
    });
    document.body.classList.toggle(ACTIVE_POPUP_CLASS, false);
    body_locked = false;
};

let backdrop;
let serial = 0;
const popups = [];

const activate_popup = (opt={})=>{
    const pop = document.createElement('div');
    pop.classList.add('eap_popup');
    const has_backdrop = !!container.querySelectorAll('.eap_backdrop').length;
    if (!has_backdrop && !opt.no_backdrop)
    {
        backdrop = document.createElement('div');
        backdrop.classList.add('eap_backdrop');
        container.appendChild(backdrop);
    }
    container.appendChild(pop);
    return pop;
};

const last = (arr=[])=>arr[arr.length-1];

function Footer({footer_content, on_ok, on_close, ok_lock, submit_label}){
    let content;
    if (footer_content)
        content = footer_content;
    else
    {
        content = <>
          <Button className="eap_action" size="m"
            on_click={on_close}>
            Cancel
          </Button>
          <Button className="eap_action" size="m" filled
            color_scheme="primary" on_click={on_ok} disabled={ok_lock}>
            {submit_label || 'Apply'}
          </Button>
        </>;
    }
    return <div className="eap_footer">{content}</div>;
}

function Popup_inner({
    title,
    children,
    on_close,
    on_ok,
    ok_lock,
    hide_actions,
    hide_header,
    coords,
    width,
    height,
    max_height,
    no_padding,
    submit_label,
    className,
    elevated,
    footer_content,
})
{
    const wrapper_ref = useRef();
    const panel_ref = useRef();
    const [styles, set_styles] = useState();
    useLayoutEffect(()=>{
        const res = {};
        if (coords)
        {
            if (coords.y >= 0)
                res.top = coords.y;
            else
                res.bottom = -coords.y;
            if (coords.x >= 0)
                res.left = coords.x;
            else
                res.right = -coords.x;
        }
        if (max_height)
            res.maxHeight = max_height;
        const {clientWidth, clientHeight} = wrapper_ref.current;
        let {offsetWidth, offsetHeight} = panel_ref.current;
        if (width)
        {
            res.width = width;
            offsetWidth = width;
        }
        if (height)
        {
            res.height = height;
            offsetHeight = height;
        }
        if (res.left+offsetWidth>clientWidth)
        {
            delete res.left;
            res.right = 0;
        }
        if (res.top+offsetHeight>clientHeight)
        {
            delete res.top;
            res.bottom = 0;
        }
        set_styles(res);
    }, [children]);
    const handle_wrapper_click = useCallback(e=>{
        if (e.target==wrapper_ref.current)
            on_close();
    }, [on_close]);
    let Tag = typeof title == 'string' ? Text.xxl : 'div';
    const hide_footer = hide_actions&&!footer_content;
    const classes = cn('eap_container', {
        eap_container_abs: !!coords,
        eap_container_no_padding: !!no_padding,
        eap_container_no_header: !!hide_header,
        eap_container_no_footer: !!hide_footer,
    });
    return <div className={cn('eap_wrapper', className)} ref={wrapper_ref}
      onClick={handle_wrapper_click}>
      <Panel className={classes} no_padding elevated={elevated}
        style={styles} ref={panel_ref}>
        {!hide_header&&<div className="eap_header">
          <Tag className="eap_title" bold no_wrap>{title}</Tag>
          <Button className="eap_close" on_click={on_close} ico="close"
            color_scheme="tertiary" filled round tip="Close"/>
        </div>}
        <div className="eap_body">
          {children}
        </div>
        {!hide_footer&&<Footer footer_content={footer_content}
          ok_lock={ok_lock} on_close={on_close} on_ok={on_ok}
          submit_label={submit_label}/>}
      </Panel>
    </div>;
}

export function Popup({
    title='',
    show,
    children,
    on_close,
    on_ok,
    ok_lock,
    hide_actions,
    hide_header,
    on_close_finished,
    coords,
    width,
    max_height,
    no_padding,
    height,
    no_backdrop,
    submit_label,
    className,
    has_shadow,
    footer_content,
})
{
    const [id] = useState(()=>serial++);
    const [open, set_open] = useState(null);
    const [wrapper, set_wrapper] = useState(null);
    const [closing, set_closing] = useState(false);
    const handle_close = useCallback(()=>{
        // ensure_close
        if (on_close&&!closing)
            on_close();
    }, [closing, on_close]);
    const handle_esc = useCallback(e=>{
        if (e.key=='Escape'&&last(popups)==id)
            handle_close();
    }, [handle_close]);
    useEffect(()=>{
        const invoke = closing;
        return ()=>{
            if (invoke&&on_close_finished)
                on_close_finished();
        };
    }, [closing, on_close_finished]);
    useEffect(()=>{
        let elem;
        if (open)
        {
            if (popups.length==0&&!coords)
                lock_body();
            popups.push(id);
            elem = activate_popup({no_backdrop});
            set_wrapper(elem);
            document.addEventListener('keyup', handle_esc, {passive: true});
        }
        return ()=>{
            if (open)
            {
                popups.pop();
                document.removeEventListener('keyup', handle_esc);
                elem.classList.add('eap_popup_out');
                set_closing(true);
            }
            const latest = popups.length==0;
            const remove_backdrop = latest&&open&&!no_backdrop;
            if (remove_backdrop)
                backdrop.classList.add('eap_backdrop_out');
            setTimeout(()=>{
                if (open)
                {
                    elem.remove();
                    set_closing(false);
                    set_wrapper(null);
                    if (remove_backdrop)
                        backdrop.remove();
                    if (latest&&body_locked)
                        unlock_body();
                }
            }, CLOSE_ANIMATION_DURATION+10);
        };
    }, [open]);
    useEffect(()=>{
        set_open(!!show);
    }, [show]);
    if (!wrapper)
        return null;
    const content = <Popup_inner title={title} on_close={handle_close}
      on_ok={on_ok} ok_lock={ok_lock} hide_actions={hide_actions}
      hide_header={hide_header} coords={coords} width={width} height={height}
      max_height={max_height} no_padding={no_padding} elevated={has_shadow}
      submit_label={submit_label} className={className}
      footer_content={footer_content}>
      {children}
    </Popup_inner>;
    return createPortal(content, wrapper);
}
