// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import etask from '/util/etask.js';
import {Panel} from '../../comp/panel';
import {Text} from '../../comp/text';
import {register_token} from '../../utils/oauth.js';
import {Page} from '../../comp/page';
import {g_path} from '../../utils/path.js';
import {parse_state} from '../../utils/url_state.js';
import './auth_success.less';

const is_redirect_ok = target=>target?.[0]=='/';

const get_redirect_path = ()=>{
    const saved_state = parse_state();
    if (is_redirect_ok(saved_state.redirect))
        return saved_state.redirect;
    return g_path('');
};

export function Auth_success(){
    const [success, set_success] = useState(false);
    const [fail, set_fail] = useState(false);
    const [fail_type, set_fail_type] = useState('');
    useEffect(()=>{
        etask(function*(){
            try {
                const res = yield register_token();
                if (res?.ok)
                    set_success(true);
                else
                {
                    if (res?.err == 'user suspended')
                        set_fail_type('suspended');
                    else
                        set_fail_type('');
                    set_fail(true);
                }
            } catch(e){
                set_fail(true);
            }
        });
    }, [success, fail]);
    if (success)
        return <Redirect to={get_redirect_path()}/>;
    if (fail)
    {
        let url = '/signin';
        if (fail_type)
            url += '?type='+fail_type;
        return <Redirect to={g_path(url)}/>;
    }
    return <Page className="ea_auth_success" sidebarless headerless has_logo>
      <Panel className="eaas_block" no_background no_border>
        <div className="eaas_content">
          <Text.l>Checking login credentials</Text.l>
          <Text.m>Redirecting...</Text.m>
        </div>
      </Panel>
    </Page>;
}
