// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useCallback, useState, useRef, useEffect} from 'react';
import cn from 'classnames';
import {is_valid_email} from '/util/url.js';
import {Icon} from '../icon';
import * as cns from './text_input.module.less';

const KEYS = {UP: 'ArrowUp', DOWN: 'ArrowDown', ENTER: 'Enter'};

const toNum = (val, int)=>{
    let value = val;
    if (int)
        value = String(value).split('.')[0];
    let parsed = int ? parseInt(value) : parseFloat(value);
    return Number.isNaN(parsed) ? 0 : parsed;
};

const get_extra = type=>{
    const extra = {};
    if (type=='email')
    {
        extra.type = 'email';
        extra.inputMode = 'email';
        extra.autoComplete = 'email';
    }
    else if (type=='num')
        extra.inputMode = 'decimal';
    return extra;
};

const validate = (value, type, min, max)=>{
    let valid = true;
    const errors = [];
    if (type=='num')
    {
        const numeric = parseFloat(value);
        if (min!==undefined&&numeric<min)
        {
            valid = false;
            errors.push(`Value should be more then ${min}`);
        }
        if (max!==undefined&&numeric>max)
        {
            valid = false;
            errors.push(`Value should be less then ${max}`);
        }
    }
    if (type=='email')
    {
        valid = is_valid_email(value);
        if (!valid)
            errors.push('Should be valid email');
    }
    return {valid, errors};
};

const add = (v='', part='')=>`${v}${v&&part? ' - ' : ''}${part}`;

const join_err = erros=>{
    let title = '';
    for (const e of erros)
        title = add(title, e);
    return title;
};
// type: 'text' | 'num' | 'email'
export function Text_input({
    value,
    className,
    placeholder='',
    on_change,
    size='m',
    type = 'text',
    min,
    max,
    int=false,
    readonly,
    on_click,
    autofocus,
    title: _title = '',
    invalid: _invalid,
    errors: _errors = [],
    on_enter,
    ico_left,
    ico_right,
    textarea,
    ...rest
})
{
    const input_ref = useRef();
    useEffect(()=>{
        if (!autofocus)
            return;
        requestAnimationFrame(()=>{
            if (input_ref.current)
                input_ref.current.focus();
        });
    }, []);
    const [valid, set_valid] = useState(!_invalid);
    const [errors, set_errors] = useState(_errors);
    const final_validity = valid&&!_invalid;
    const handle_change = useCallback(e=>{
        let val = e;
        if (e.target)
            val = e.target.value;
        if (on_change)
            on_change(type == 'num' ? toNum(val, int) : val);
        set_valid(true);
        set_errors([]);
    }, [on_change, type]);
    const handle_key = useCallback(e=>{
        if (readonly)
            return;
        switch (e.key)
        {
        case KEYS.UP:
            if (type == 'num')
                handle_change(parseInt(value)+1);
            break;
        case KEYS.DOWN:
            if (type == 'num')
                handle_change(parseInt(value)-1);
            break;
        case KEYS.ENTER:
            if (on_enter)
                on_enter();
            break;
        }
    }, [readonly, value, type, handle_change, on_enter]);
    const handle_focus = useCallback(()=>{
        set_valid(true);
        set_errors([]);
    }, []);
    const handle_blur = useCallback(()=>{
        const res = validate(value, type, min, max);
        set_valid(res.valid);
        set_errors(res.errors);
    }, [value, type, min, max]);
    const classes = [cns.input, cns[`input__size_${size}`]];
    if (!final_validity)
        classes.push(cns.input__invalid);
    if (on_click)
        classes.push(cns.input__clickable);
    if (ico_left)
        classes.push(cns.input__left_ico);
    if (ico_right)
        classes.push(cns.input__right_ico);
    const title = add(_title, join_err(errors));
    const Tag = !textarea ? 'input' : 'textarea';
    return <div className={cn(cns.root, className)}>
      {ico_left&&<Icon className={cns.icon} ico={ico_left} size="xs"/>}
      <Tag className={cn(classes)} value={value}
        placeholder={placeholder} ref={input_ref} title={title}
        onChange={handle_change} readOnly={readonly}
        onKeyDown={handle_key} onClick={on_click} onBlur={handle_blur}
        onFocus={handle_focus} {...get_extra(type)} {...rest}/>
      {ico_right&&<Icon className={cn(cns.icon, cns.icon__right)}
        ico={ico_right} size="xs"/>}
    </div>;
}
