// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Tooltip} from '../tooltip';
import * as cns from './text.module.less';

const headings = {h: 1, H: 1};
const tags_low = `h1 h2 h3 h4 h5 h6 p span strong`;
const tags_up = tags_low.toUpperCase();
const tags = `${tags_low} ${tags_up}`.split(' ');
const sizes = `xxxs xxs xs s m l xl xxl xxxl`.split(' ');

const find_tag = props=>{
    for (let tag of tags)
    {
        if (props[tag])
            return tag;
    }
    return 'p';
};

function Text_comp({
    size,
    children,
    light,
    ghost,
    className,
    mono,
    bold,
    title,
    disabled,
    secondary,
    tertiary,
    accent,
    primary,
    success,
    error,
    warn,
    white,
    tip,
    tip_placement,
    no_wrap,
    ...props
})
{
    const Tag = find_tag(props);
    let is_bold = bold;
    if (headings[Tag[0]])
        is_bold = true;
    const classes = cn(cns.root, cns[`root__size_${size}`], {
        [cns.root__mono]: mono,
        [cns.root__bold]: is_bold,
        [cns.root__light]: light,
        [cns.root__ghost]: ghost,
        [cns.root__disabled]: disabled,
        [cns.root__secondary]: secondary,
        [cns.root__tertiary]: tertiary,
        [cns.root__primary]: primary,
        [cns.root__success]: success,
        [cns.root__error]: error,
        [cns.root__warn]: warn,
        [cns.root__white]: white,
        [cns.root__accent]: accent,
        [cns.root__no_wrap]: no_wrap,
    }, className);
    return <Tooltip tip={tip} placement={tip_placement}>
      <Tag className={classes} title={title}>
        {children}
      </Tag>
    </Tooltip>;
}

const gen_text = size=>function Text_proxy({...props}){
    return <Text_comp size={size} {...props}/>;
};

export const Text = sizes.reduce((acc, size)=>{
    acc[size] = gen_text(size);
    return acc;
}, {});
