// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import {useEffect, useState} from 'react';
import cookie from 'cookie';
import {ms} from '/util/date.js';
import etask from '/util/etask.js';
import * as api from '../api.js';
import {XSRF_COOKIE} from './constants.js';
import {parse_qs} from './path.js';

export function use_win_scroll(handler){
    useEffect(()=>{
        const fn = ()=>handler?.();
        document.addEventListener('scroll', fn, {passive: true});
        return ()=>{
            document.removeEventListener('scroll', fn);
        };
    }, [handler]);
}

const update_xsrf = (update, callback)=>etask(function*(){
    const current = cookie.get(XSRF_COOKIE);
    if (!current)
        yield update();
    callback();
});

export function use_xsrf_rotation(update){
    const [ready, set_ready] = useState(false);
    useEffect(()=>{
        const updater = update_xsrf.bind(1, update, ()=>set_ready(true));
        const interval = setInterval(updater, 10*ms.MIN);
        updater();
        return ()=>clearInterval(interval);
    }, []);
    return ready;
}

export function use_instant_redirect(meta){
    const [redirect] = useState(()=>{
        const params = parse_qs() || {};
        if (!params.instant)
            return;
        const p_meta = meta?.[params.provider];
        if (!p_meta?.enabled)
            return;
        return p_meta.url;
    });
    return redirect;
}

export function use_zendesk_sso(){
    useEffect(()=>{
        let qs = parse_qs();
        if (qs.zendesk_sso)
        {
            api.get_zendesk_sso(encodeURIComponent(qs.return_to))
                .then(({link})=>{
                    window.location = link;
                })
                .catch(console.error);
        }
    }, []);
}
