// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';
import {Icon} from '../icon';
import {Text} from '../text';
import {limit_txt} from '../../utils/misc.js';
import * as cns from './user.module.less';

const DEFAULT_TITLE = 'Looks perfect!';
const LONG_NAME_LEN = 18;
const SUPERLONG_NAME_LEN = 28;

const DEFAULT_NAME = 'Incognito';
const DEFAULT_ADAPTIVE = {
    hide_name: true,
};

const render_name = (user, big, name_bottom)=>{
    const name = user.name || DEFAULT_NAME;
    let Name_comp_big = name_bottom ? Text.xl : Text.xxl;
    const Name_comp = user.picture&&big
        ? Name_comp_big
        : Text.s;
    let content;
    if (big)
    {
        content = [];
        let Name_piece_comp = name.length>LONG_NAME_LEN
            ? Text.s
            : Name_comp_big;
        if (name.length>SUPERLONG_NAME_LEN)
            Name_piece_comp = Text.xs;
        const parts = name.split(/\s+/);
        for (let i=0; i<parts.length; i++)
        {
            content.push(<Name_piece_comp key={parts[i]+i} span>
              {parts[i]}{' '}
            </Name_piece_comp>);
        }
    }
    else
        content = limit_txt(name, LONG_NAME_LEN);
    return <Name_comp className={cns.name}>
      {content}
    </Name_comp>;
};

export function User({
    className,
    user,
    on_click,
    to,
    adaptive_opts=DEFAULT_ADAPTIVE,
    big,
    no_bg,
    name_placement='right',
})
{
    const Comp = on_click
        ? 'button'
        : to ? Link : 'div';
    const name_bottom = name_placement=='bottom';
    const classes = cn(cns.root, {
        [cns.root__clickable]: !!(on_click||to),
        [cns.root__narrow_hide_name]: !!adaptive_opts?.hide_name,
        [cns.root__big]: !!big,
        [cns.root__no_bg]: !!no_bg,
        [cns.root__name_bottom]: name_bottom,
    }, className);
    const props = {};
    if (on_click)
        props.onClick = on_click;
    else if (to)
        props.to = to;
    const tip = big ? DEFAULT_TITLE : user.name;
    return <Comp className={classes} {...props}>
      <Icon className={cns.avatar} as="img"
        tip_placement={name_bottom ? 'top' : 'bottom'}
        tip={tip} src={user.picture} ico="default_avatar"/>
      <div className={cns.name_wrap}>
        {big&&!name_bottom&&<Text.l tertiary className={cns.name}>
          User name
        </Text.l>}
        {render_name(user, big, name_bottom)}
      </div>
    </Comp>;
}
