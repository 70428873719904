// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import * as oauth from '../../utils/oauth.js';
import {g_path, get_path, parse_qs, go_to} from '../../utils/path.js';
import {use_instant_redirect} from '../../utils/hooks.js';
import {Panel} from '../../comp/panel';
import {Page} from '../../comp/page';
import {Text} from '../../comp/text';
import {Oauth_btn} from '../../comp/oauth_btn';
import './signin.less';

export function Signin(){
    const [qs] = useState(()=>parse_qs());
    const err_msg = qs?.type=='suspended' ? 'Account Suspended' : '';
    const meta = oauth.get_meta();
    const redirect = use_instant_redirect(meta);
    if (redirect)
        return void go_to(redirect);
    return <Page className="ea_signin" sidebarless headerless has_logo>
      <Panel className="eas_block" no_background no_border>
        {err_msg&&<Text.m className="eas_title" h2 bold error>
          Account Suspended
        </Text.m>}
        <Text.m className="eas_title" h2 bold>{meta.action_label}</Text.m>
        <div className="eas_separator"/>
        <ul className="eas_actions">
          {meta.google.enabled&&<li className="eas_action">
            <Oauth_btn url={meta.google.url} logo="google_logo" size="l">
              {meta.action_label} with Google
            </Oauth_btn>
          </li>}
          {meta.apple.enabled&&<li className="eas_action">
            <Oauth_btn url={meta.apple.url} logo="apple_logo" size="l">
              {meta.action_label} with Apple
            </Oauth_btn>
          </li>}
        </ul>
        <div className="eas_switch">
          <Text.s className="eas_qestion">Don't have an account?</Text.s>
          <Link className="eas_link" to={get_path(g_path('/signup'))}>
            Sign up
          </Link>
        </div>
      </Panel>
    </Page>;
}
