// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import * as shared_constants from '../shared/constants.js';
import * as env from './env.js';

export const APP_ID = window.APP_ID;
if (!APP_ID)
    throw new Error('APP_ID not found!');
export const AVAILABLE_FEATURES = window.AVAILABLE_FEATURES;
if (!AVAILABLE_FEATURES)
    throw new Error('AVAILABLE_FEATURES not found!');
export const APP_TITLE = window.APP_TITLE;
if (!APP_TITLE)
    throw new Error('APP_TITLE not found!');

export const APPLE_OAUTH_ENABLED = window.APPLE_OAUTH_ENABLED;
export const WISE_ENABLED = window.WISE_ENABLED;
export const DASHBOARD_DISABLED = window.DASHBOARD_DISABLED;
export const FEATURES = {
    TRANSACTIONS: 'transactions',
    PROMO: 'promotions',
    PROFILE: 'profile',
    REFERRAL: 'referral',
    REFERRAL_BVPN: 'referral_bvpn',
    NOTIFS: 'notifications',
    DOWNLOADS: 'downloads',
    USAGE_CHART: 'usage_chart',
    FAQ: 'faq',
    SOCIAL: 'social',
};
export const DOMAIN = location.hostname;
export const PREFIX = shared_constants.CP_PREFIX;
export const REFERRAL_BASE = `https://${DOMAIN}/i/`;
export const REFERRAL_BASE_BVPN = 'https://brightvpn.com/?r=';
export const FAQ_LINK = 'earnapp'==APP_ID
    ? 'https://help.earnapp.com' : `https://${DOMAIN}/faq`;
export const FAQ_LINK_REFERRAL = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10232037405073-How-does-the-referral-program-work-';
export const FAQ_LINK_BVPN_REFERRAL = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10247641869969-What-is-EarnApp-s-Referral-Program-';
export const FAQ_LINK_BVPN_REF_RATES = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10247867472401-What-are-the-rates-';
export const FAQ_LINK_VPN = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10201052442897-Why-was-my-IP-banned-';
export const FAQ_LINK_INSTALL = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10261224561553-Installation-instructions';
export const FEES_LINK = 'https://help.earnapp.com/hc/en-us/articles/'
    +'10147389004049-What-are-the-transaction-fees-on-redeems';
export const APPESTEEM_LINK = 'https://customer.appesteem.com/certified?vendor=BDATA';
export const EULA_LINK = `https://${DOMAIN}/user-agreement`;
export const PRIVACY_LINK = `https://${DOMAIN}/privacy`;
export const VERSION = window.ea_conf?.ver;
export const LS_PREFIX = 'earnapp';
export const MOBILE_WIDTH = 768;
export const REF_Q_PARAM = 'r';
export const LINK_Q_PARAM = 'link_device';
export const PLATFORM_Q_PARAM = 'platform';
export const TIME_FMT = '%H:%M';
export const DATE_TIME_FMT = '%d-%m-%Y %H:%M';
export const DATE_TIME_US_FMT = '%m-%d-%Y %H:%M';
export const DATE_FMT = '%d-%m-%Y';
export const DATE_FMT_SHORT = '%b %d';
export const DATE_FMT_LONG = '%B %-d, %Y';
export const XSRF_COOKIE = 'xsrf-token';
export const XSRF_HEADER = 'xsrf-token';
export const XSRF_STATE = 'xsrf';
export const METHODS = shared_constants.METHODS;
export const P_METHOD_META = {
    [METHODS.PAYPAL]: {
        title: 'PayPal',
        ico: 'paypal_logo',
        email_label: 'PayPal account email',
    },
    [METHODS.AMAZON]: {
        title: 'Amazon Gift Card',
        ico: 'amazon_logo',
        email_label: 'Email to receive the gift card',
    },
    [METHODS.WISE]: {
        title: 'Wise',
        ico: 'wise_logo',
        email_label: 'Wise email',
    },
};
export const METHOD_KINDS = [
    METHODS.PAYPAL,
    METHODS.AMAZON,
];
if (WISE_ENABLED)
    METHOD_KINDS.push(METHODS.WISE);
export const EXECUTION_DURATIONS = {
    [METHODS.PAYPAL]: 7,
    [METHODS.AMAZON]: 10,
    [METHODS.WISE]: 7,
};
export const PLATFORMS = {
    WINDOWS: 'win',
    LINUX: 'linux',
    MACOS: 'mac',
    IOS: 'ios',
    ANDROID: 'android',
    PI: 'pi',
};
export const ROUTES = {
    TRANSACTIONS: 'transactions',
    REFERRALS: 'referrals',
    REFERRALS_BVPN: 'referrals_bvpn',
    PASSIVE_INCOME: 'passive_income',
    PROMOTIONS: 'promotions',
    FAQ: 'faq',
    MAINTENANCE: 'maintenance',
    OFFERS: 'offers',
    ME: 'profile',
    CASHOUT: 'cashout',
};
export const STORAGE_KEYS = {
    USER: 'root.user',
};
export const SUPPORT_EMAIL = 'support@earnapp.com';
export const BILLING = {
    UPTIME: 'uptime',
    BANDWIDTH: 'bandwidth',
    FIXED: 'fixed'
};
export const UPTIME_BILLING = [BILLING.UPTIME, BILLING.FIXED];
export const DEF_PERIOD_ID = 1;
export const PERIODS = [
    {id: 0, title: 'All time'},
    {id: 1, title: 'Last month'},
];

export const IS_IOS = env.IS_IOS;
export const IS_ANDROID = env.IS_ANDROID;
export const IOS_WEBVIEW = env.IOS_WEBVIEW;

export const OFW_TYPE = {
    AUTH: 'auth',
    NO_AUTH: 'no_auth',
};
export const AUTH_TAB = {
    SIGN_IN: 'sign_in',
    SIGN_UP: 'sign_up',
};
export const OFFER_STATUS = {
    NEW: 'new',
    STARTED: 'started',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    STALE: 'stale',
};
export const OFFER_REWARD_STATUS = {
    NEW: 'new',
    COMPLETED: 'completed',
};
export const OFFER_STATUS_META = {
    [OFFER_STATUS.NEW]: {name: 'New'},
    [OFFER_STATUS.STARTED]: {name: 'In process'},
    [OFFER_STATUS.IN_PROGRESS]: {name: 'In process'},
    [OFFER_STATUS.COMPLETED]: {name: 'Completed'},
};
export const OFFER_KIND = {
    TASK: 'tasks',
    GAME: 'games',
};
export const OFFER_KIND_META = {
    [OFFER_KIND.TASK]: {
        category_name: 'Tasks',
        item_name: 'Task',
    },
    [OFFER_KIND.GAME]: {
        category_name: 'Games',
        item_name: 'Game',
    },
};
export const OFFERS_SORTING = {
    FAV: 'fav',
    AMOUNT_19: 'amount_asc',
    AMOUNT_91: 'amount_desc',
    START_TS_19: 'date_asc',
    START_TS_91: 'date_desc',
};
export const YEAR = new Date().getFullYear();
export const NEW_TAB_ATTRS = {
    target: '_blank',
    rel: 'noreferrer noopener'
};
export const OAUTH_PROVIDER = shared_constants.OAUTH_PROVIDER;
