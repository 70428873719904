// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import {useMemo} from 'react';
import {useStore} from '@nanostores/react';
import {$is_root} from '../../store/user.js';
import {METHODS, METHOD_KINDS, P_METHOD_META} from '../../utils/constants.js';
import store from '../../store.js';

export const is_amazon = method=>method?.startsWith('amazon') || false;

export const get_amazons = methods_map=>{
    const res = [];
    for (const [, m] of Object.entries(methods_map))
    {
        if (m.parent==METHODS.AMAZON)
            res.push(m);
    }
    return res;
};

export function use_amazons(meta){
    return useMemo(()=>get_amazons(meta).map(m=>m.value), [meta]);
}

const is_for_root = (id, params)=>!!params[id]?.for_root;

const id2rec = id=>({id, title: P_METHOD_META[id].title});

export const get_pmethods = (methods_params, is_root)=>{
    const list = METHOD_KINDS.map(id2rec);
    return list.filter(m=>!is_for_root(m.id, methods_params) || is_root);
};

export function use_payment_methods(){
    const [methods_params] = store.use_methods();
    const is_root = useStore($is_root);
    return useMemo(()=>
        get_pmethods(methods_params, is_root), [methods_params, is_root]);
}

export const get_meta = method=>
    P_METHOD_META[method.parent||method.value] || {};

export const get_min_redeem = (method, metas)=>{
    const meta = metas[method];
    if (meta)
        return meta.min_redeem;
    let min = Infinity;
    if (is_amazon(method))
    {
        const amazons = get_amazons(metas);
        for (const m of amazons)
            min = Math.min(min, m.min_redeem);
    }
    return min===Infinity ? 25 : min;
};
