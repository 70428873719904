// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {Icon} from '../../comp/icon';
import {Text} from '../../comp/text';
import * as cns from './method_card.module.less';

export function Method_card({
    className,
    on_click,
    disabled,
    ico,
    label,
    description,
    active,
    slot_bottom,
})
{
    return <button className={cn(cns.btn, active&&cns.btn__active, className)}
      onClick={on_click} disabled={disabled} type="button"
      tabIndex={active ? -1 : undefined}>
      <div className={cns.ico_wrap}>
        <Icon className={cns.ico} as="img" ico={ico}/>
      </div>
      <Text.m>{label}</Text.m>
      {description&&<Text.xs span secondary className={cns.desc}>
        {description}
      </Text.xs>}
      {slot_bottom}
    </button>;
}
