// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import {APP_TITLE} from '../../utils/constants.js';
import * as cns from './splash_screen.module.less';

export function Splash_screen(){
    return <div className={cns.root}>
      <div className={cn(cns.logo, 'ea_logo')}
        aria-label={`${APP_TITLE} Logo`}/>
    </div>;
}
