// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import cookie from 'cookie';
import etask from '/util/etask.js';
import * as api from '../api.js';
import {parse_qs} from './path.js';
import {PREFIX, DOMAIN, XSRF_COOKIE, XSRF_HEADER, XSRF_STATE, OAUTH_PROVIDER,
    IOS_WEBVIEW, APPLE_OAUTH_ENABLED} from './constants.js';
import {stringify_state, parse_state} from './url_state.js';

const CALLBACK_URL = `https://${DOMAIN}${PREFIX}/auth/google/callback`;
const XSRF_EROR = 'Missing XSRF';

export const get_url = (provider, is_signup)=>{
    const state = stringify_state({
        [XSRF_STATE]: cookie.get(XSRF_COOKIE),
    });
    const getter = is_signup ? api.get_signup_url : api.get_signin_url;
    return `${getter(provider)}?${state}`;
};

export const get_meta = is_signup=>{
    return {
        action_label: is_signup ? 'Sign Up' : 'Sign In',
        google: {
            url: get_url(OAUTH_PROVIDER.GOOGLE, is_signup),
            enabled: !IOS_WEBVIEW,
        },
        apple: {
            url: get_url(OAUTH_PROVIDER.APPLE, is_signup),
            enabled: APPLE_OAUTH_ENABLED,
        },
    };
};
// google only at the moment
export const register_token = ()=>etask(function*(){
    const state = parse_state();
    const xsrf_token = state?.[XSRF_STATE];
    if (!xsrf_token)
        throw new Error(XSRF_EROR);
    const qparams = parse_qs();
    return yield api.exchange_token({
        [XSRF_HEADER]: xsrf_token,
        type: OAUTH_PROVIDER.GOOGLE,
        code: qparams.code,
        redirect_uri: CALLBACK_URL
    });
});
