// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import {atom, onMount} from 'nanostores';
import debounce from 'lodash4/debounce.js';

const DEBOUNCE_MS = 500;
const OPTS = {passive: true};

export const $size = atom([null, null]);

const get_win_size = ()=>[
    document.documentElement.clientWidth,
    document.documentElement.clientHeight,
];

onMount($size, ()=>{
    const handler = ()=>{
        const dims = get_win_size();
        const cur = $size.value;
        if (cur[0]==dims[0] && cur[1]==dims[1])
            return;
        $size.set(dims);
    };
    handler();
    const debounced = debounce(handler, DEBOUNCE_MS, {trailing: true});
    window.addEventListener('resize', debounced, OPTS);
    screen?.orientation?.addEventListener('change', debounced, OPTS);
    return ()=>{
        window.removeEventListener('resize', debounced);
        screen?.orientation?.removeEventListener('change', debounced);
    };
});
