// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import Tooltip_comp from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import * as cns from './tooltip.module.less';
import './animation.less';

export const ANIMATION = {
    ZOOM: 'zoom',
    NOPE: 'nope',
};

const TRIGGERS = ['hover'];
const CLASSNAMES = {root: cns.root, body: cns.inner};
const DELAY_SEC = 0.2;
const ANIMATION_META = {
    [ANIMATION.ZOOM]: {motionName: 'ea-tooltip-zoom'},
};

export function Tooltip({
    tip,
    children,
    trigger=TRIGGERS,
    placement='bottom',
    show_delay_s=DELAY_SEC,
    hide_delay_s=DELAY_SEC,
    animation=ANIMATION.ZOOM,
})
{
    if (!tip)
        return children;
    return <Tooltip_comp placement={placement} classNames={CLASSNAMES}
      trigger={trigger} overlay={tip} destroyOnClose showArrow={false}
      mouseEnterDelay={show_delay_s} motion={ANIMATION_META[animation]}
      mouseLeaveDelay={hide_delay_s}>
      {children}
    </Tooltip_comp>;
}
