// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import cn from 'classnames';
import store from '../../store.js';
import {fmt_money} from '../../utils/misc.js';
import {Text} from '../text';
import {Icon} from '../icon';
import {Countdown} from '../countdown';
import {Popup_btn as Withdraw_btn} from '../../features/redeem';
import * as cns from './balance.module.less';

const get_tooltip = meta=>{
    const {ref_bonuses, promo_bonuses, ref_bvpn, ref_hola_browser} = meta;
    let title = '';
    if (ref_bonuses)
        title = `including ${fmt_money(ref_bonuses)} from my referrals`;
    if (promo_bonuses)
    {
        title += title ? ' and ' : 'including ';
        title += `${fmt_money(promo_bonuses)} from promotions`;
    }
    if (ref_bvpn)
    {
        title += title ? ' and ' : 'including ';
        title += `${fmt_money(ref_bvpn)} from my Bright VPN referrals`;
    }
    if (ref_hola_browser)
    {
        title += title ? ' and ' : 'including ';
        title += `${fmt_money(ref_hola_browser)} from my`
            +` Hola Browser referrals`;
    }
    return title;
};

export function Balance({
    className,
    compact,
    caption='Current Balance',
    big,
    has_withdraw,
})
{
    const [money_meta] = store.use_money();
    const {balance} = money_meta;
    const title = get_tooltip(money_meta);
    const clasess = cn(cns.root, {
        [cns.root__compact]: !!compact,
        [cns.root__big]: !!big,
    }, className);
    const Value_comp = compact
        ? Text.s
        : big
            ? Text.xxl
            : Text.l;
    const Value_wrap = has_withdraw ? 'div' : React.Fragment;
    let v_wrap_props = {};
    if (has_withdraw)
        v_wrap_props = {className: cns.value_row};
    return <div className={clasess}>
      <div className={cns.row}>
        <div className={cns.ico_wrap}>
          <Icon className={cns.ico} ico="money" color="accent"
            size={compact ? 'm' : 'xl'}/>
        </div>
        <div className={cns.value_wrap}>
          {!compact&&<Text.s tertiary>{caption}</Text.s>}
          <Value_wrap {...v_wrap_props}>
            <Value_comp className={cns.value} tip={title} span bold={!compact}>
              {fmt_money(balance)}
            </Value_comp>
            {has_withdraw&&<Withdraw_btn is_redeem/>}
          </Value_wrap>
        </div>
      </div>
      {!compact&&<Text.s className={cns.update_row}>
        <Text.xs tertiary span>Earnings update in:</Text.xs>
        <Countdown className={cns.counter} counter="balance_sync" units={1}
          size="s"/>
      </Text.s>}
    </div>;
}
