// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React, {useState, useCallback, useEffect, useRef} from 'react';
import cn from 'classnames';
import {Text_input} from '../text_input';
import {Popup} from '../popup';
import {Button} from '../button';
import {use_win_scroll} from '../../utils/hooks.js';
import * as cns from './dropdown.module.less';

function Item({on_click, item, active, autofocus}){
    const handle_click = useCallback(()=>{
        if (on_click)
            on_click(item.id);
    }, [item.id, on_click]);
    const disabled = active || item.disabled;
    return <li>
      <Button on_click={handle_click} disabled={disabled} filled={!!active}
        className={cns.button} ico={item.ico} size="s" align="left"
        color_scheme="secondary" view="link" auto_focus={autofocus}>
        {item.title}
      </Button>
    </li>;
}

const normalize = items=>{
    return items.map(i=>{
        if (typeof i=='string')
            return {id: i, title: i};
        return i;
    });
};

export function Dropdown({
    value,
    placeholder='Select option',
    items: _items=[],
    className,
    on_change,
    autofocus,
    size='m',
    disabled,
})
{
    const items = normalize(_items);
    const wrapper_ref = useRef();
    const [opened, set_opened] = useState(false);
    const [coors, set_coords] = useState(null);
    const [popup_w, set_popup_w] = useState(null);
    const [popup_h, set_popup_h] = useState(null);
    const toggle = useCallback(()=>{
        requestAnimationFrame(()=>{
            const node = wrapper_ref.current;
            if (!node)
                return;
            const {x, y, height, width} = node.getBoundingClientRect();
            const v_start = y+height+8;
            set_coords({x, y: v_start});
            set_popup_w(width);
            set_popup_h(window.innerHeight-v_start-24);
            set_opened(v=>!v);
        });
    }, []);
    const handle_select = useCallback(id=>{
        on_change(id);
        toggle();
    }, []);
    useEffect(()=>{
        if (!autofocus)
            return;
        requestAnimationFrame(()=>toggle());
    }, []);
    const handle_scroll = useCallback(()=>set_opened(false), []);
    use_win_scroll(handle_scroll);
    const selected = items.find(i=>i.id==value);
    return <div className={cn(cns.root, opened&&cns.root__opened, className)}
      ref={wrapper_ref}>
      <Text_input className={cns.input} value={selected?.title||placeholder}
        readonly on_click={toggle} ico_left={selected?.ico} size={size}
        disabled={disabled}/>
      <Popup show={opened} hide_header hide_actions on_close={toggle}
        coords={coors} width={popup_w} max_height={popup_h} no_padding
        no_backdrop has_shadow>
        <div>
          <ul className={cns.list}>
            {items.map((i, idx)=><Item key={i.id} item={i} autofocus={idx==0}
              on_click={handle_select} active={i.id==selected?.id}/>)}
          </ul>
        </div>
      </Popup>
    </div>;
}
