// LICENSE_CODE ZON
'use strict'; /*jslint react:true*/
import React from 'react';
import {Button} from '../../comp/button';
import {Text} from '../../comp/text';
import app_logo_src from '../../img/earnapp_logo.svg';
import appesteem_logo_src from '../../img/appesteem_logo.png';
import {YEAR, APPESTEEM_LINK, NEW_TAB_ATTRS} from '../../utils/constants.js';
import {Social} from './social.js';
import meta from './meta.json';
import * as cns from './footer.module.less';

function Section_item({data}){
    return <li>
      <Button href={data.url} hide_new_tab_ico left_shift view="link" size="l"
        align="left" standard_link className={cns.link}>
        {data.name}
      </Button>
    </li>;
}

function Section({name, items, children}){
    return <li>
      <Text.m className={cns.section_name} primary bold>{name}</Text.m>
      <div className={cns.section_body}>
        {children
            ? children
            : <ul className={cns.links_list}>
                {items?.map(item=><Section_item key={item.name} data={item}/>)}
            </ul>
        }
      </div>
    </li>;
}

function Copyright(){
    return <div className={cns.copyright}>
        <Text.m secondary>
          &copy; Copyright {YEAR} Bright Data Ltd.{' '}
          | All&nbsp;rights&nbsp;reserved
        </Text.m>
        <Text.m secondary>
          Bright Data Ltd. (Headquarters), 3 Hamahshev St.,{' '}
          Netanya 4250713, Israel (POB 8025)
        </Text.m>
    </div>;
}

export function Footer(){
    return <footer className={cns.root}>
      <div className={cns.wrapper}>
        <div className={cns.row}>
          <a className={cns.link_wrapper} href="/">
            <img className={cns.app_logo} src={app_logo_src}/>
          </a>
          <ul className={cns.sections_list}>
            {meta.sections.map(s=>
              <Section key={s.name} name={s.name} items={s.items}/>)}
            <Section name="Certified by">
              <a className={cns.link_wrapper} href={APPESTEEM_LINK}
                {...NEW_TAB_ATTRS}>
                <img className={cns.appesteem_logo} src={appesteem_logo_src}/>
              </a>
            </Section>
          </ul>
        </div>
        <Social className={cns.social} items={meta.social}/>
        <hr className={cns.separator}/>
        <Copyright/>
      </div>
    </footer>;
}
